<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button></p>
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Customershop:Save'">新增</el-button></div>
                <div class="h-b"><el-button type="text" size="small" @click="save" v-right-code="'Customershop:Save'">保存</el-button></div>
            </div>
        </div>
        <div class="form-list">
            <el-form ref="_customerShopForm" :model="dataSource" :rules="customerShopCheckRule">
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主名称：</el-col>
					<el-col :span="7">
						<el-form-item prop="CustomerName">
						<!-- 	<el-input v-model="dataSource.EnterpriseName" :maxlength="30" :minlength="4"
								placeholder="客户名称"></el-input> -->
								<el-select v-model="dataSource.CustomerCode" collapse-tags placeholder="请选择"
									:maxlength="200" :disabled="isDisabled">
									<el-option v-for="item in dataSource.CustomerList" :key="item.Key"
										:label="item.Value" :value="item.Key">
									</el-option>
								</el-select>
								
						</el-form-item>
					</el-col>
					<div :hidden="!isDisabled">
						<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编码：</el-col>
						<el-col :span="7">
							<el-form-item prop="CustomerCode">
								<el-input v-model="dataSource.CustomerCode" :maxlength="30" :minlength="4"
									:disabled="true" placeholder="客户编码"></el-input>
							</el-form-item>
						</el-col>
					</div>
				</el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>店铺编码：</el-col>
                    <el-col :span="7">
                        <el-input v-model="dataSource.ShopNo" disabled :maxlength="30" :minlength="4" placeholder="店铺编码"></el-input>
                    </el-col>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>店铺名称：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ShopName">
                            <el-input v-model="dataSource.ShopName"  :maxlength="30" :minlength="4" placeholder="店铺名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">联系人姓名：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ShopContactName">
                            <el-input v-model="dataSource.ShopContactName" :maxlength="30" :minlength="4" placeholder="联系人姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">联系人电话：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ShopContactPhone">
                            <el-input v-model="dataSource.ShopContactPhone" :maxlength="11" :minlength="4" placeholder="联系人电话"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">外部编码：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ExternalNo">
                            <el-input v-model="dataSource.ExternalNo" :maxlength="200" placeholder="外部编码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">地区：</el-col>
                    <el-col :span="9">
                        <Distpicker :placeholders="dataSource"></Distpicker>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="ShopAddress">
                            <el-input v-model="dataSource.ShopAddress" :maxlength="200" placeholder="详细地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">描述：</el-col>
                    <el-col :span="17">
                        <el-form-item prop="ShopDesc">
                            <el-input type="textarea" v-model="dataSource.ShopDesc" :maxlength="200" placeholder="描述"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            var checkShopName = function (rule, value, callback) {
                if (!value) return callback(new Error('店铺名称不能为空'));
                callback();
            };
            return {
                multipleSelection: [],
                isDisabled: false,
                customerShopCheckRule: {
                    ShopName: [{ validator: checkShopName, trigger: 'blur' }]
                },
                currentSelectedRow: {}
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            dataSource: {}
        },
        mounted() {
            this.Event.$on("clearEditForm", () => this.resetForm);
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = null != this.dataSource.ShopID;
                },
                deep: true
            }
        },
        created: function () {
            this.Event.$on("clearForm", this.resetForm);
        },
        methods: {
            add() {
                this.Event.$emit("onAdd");
            },
            resetForm() {
                !this.isPassValidate && this.$refs['_customerShopForm'].resetFields();//清空表单
            },
            save() {
                var _this = this;
                _this.$refs["_customerShopForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                        var routeName = _this.dataSource.ShopID === null ? "add" : "save";
                        _this.$ajax.send("omsapi/customershop/" + routeName, "post", _this.dataSource, (data) => {
                            _this.$parent.syncDataSource();
                            _this.Event.$emit("reloadPageList", data.Result);
                            _this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            },
            onCustomerShopSelectedRow: function (row) {
                this.currentSelectedRow = row;
            }
        }
    }
</script>

<style>
</style>